import { useAdContext } from "@/contexts/AdContext";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";
import { useDetectAdBlock } from "adblock-detect-react";
import clsx from "clsx";

const Ad = ({ placementName, className = "", targeting = {} }) => {
  const ads = useAdContext();

  const adBlockDetected = useDetectAdBlock();

  if (ads.showAds) {
    return (
      <>
        {!adBlockDetected && (
          <FreestarAdSlot
            publisher="mob-co-uk"
            placementName={placementName}
            targeting={targeting}
            classList={[
              "Ad",
              "flex",
              "items-center",
              "justify-center",
              "[&>div:not(:empty)]:my-8",
              "print:!hidden",
              ...className.split(" "),
            ]}
          />
        )}
        {adBlockDetected && (
          <div className={clsx("px-6 lg:px-12")}>
            <div
              className={clsx(
                "mx-auto flex max-w-screen-sm flex-col items-center justify-center rounded-2xl border border-dashed border-zinc-300 p-6 text-center lg:p-12"
              )}
            >
              <div className={clsx("heading-xs--spatial mb-2")}>
                It looks like you have an ad blocker enabled
              </div>
              <div className={clsx("font-body text-sm text-zinc-500")}>
                Please consider disabling your ad blocker to support us.
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default Ad;
